<template>
  <transition name="fade" mode="out-in">
    <router-view class=" mt-3 mb-3"></router-view>
  </transition>
</template>

<script>
export default {

}
</script>

<style>

</style>