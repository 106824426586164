<template>
  <div id="mobile-menu">
      <div class="row text-white">
        <!-- <div class="col-6 py-2 "  @click="$emit('closeMobileMenu')">
          <router-link to="/admin/agenda" class="col text-center">
              <span class="d-md-inline-block bg-dark "><i class="fa fa-calendar-alt mr-2" aria-hidden="true"></i> Agenda</span>
          </router-link>
        </div> -->
        <div class="col-6 py-2 "  @click="$emit('closeMobileMenu')" v-if="permissoes.Empresas && permissoes.Empresas.Empresas > 0">
          <router-link to="/admin/empresas" class="col text-center">
              <span class="d-md-inline-block"><i class="fa fa-briefcase  mr-2" aria-hidden="true"></i>Empresas</span>
          </router-link>
        </div>
        <div class="col-6 py-2 " @click="$emit('closeMobileMenu')" v-if="permissoes['Pessoa Física']['Pessoa física'] > 0">
          <router-link to="/admin/funcionarios" class="col text-center">
              <span class="d-md-inline-block" ><i class="fa fa-users  mr-2" aria-hidden="true"></i>Funcionários</span>
          </router-link>
        </div>
        <div class="col-6 py-2 " @click="$emit('closeMobileMenu')" v-if="permissoes.Treinamento['Cadastro de treinamentos'] && permissoes.Treinamento['Cadastro de treinamentos'] > 0">
          <router-link to="/admin/treinamentos" class="col text-center">
              <span class="d-md-inline-block"><i class="fas fa-bullhorn mr-2"></i>Treinamentos</span>
          </router-link>
        </div>
        <div class="col-6 py-2 " @click="$emit('closeMobileMenu')"  v-if="permissoes['Configurações'] && permissoes['Configurações']['Cadastro de usuários'] > 0">
          <router-link to="/admin/configuracoes" class="col text-center">
              <span class="d-md-inline-block "><i class="mr-1 fas fa-cogs"></i>Configurações</span>
          </router-link>
        </div>
      </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      permissoes: []
    }
  },
  created() {
    this.permissoes = this.$store.getters.permissoes
  },
}
</script>

<style>
  #mobile-menu a {
    color: #fff;
  }
  #mobile-menu {
    display: none;
  }
  @media only screen and (max-width: 992px) {
    #mobile-menu {
        display: block;
    }
  }
</style>