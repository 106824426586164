<template>
  <footer id="footer" class="p-3 mt-5">
    <div class="row">
        <div class="col">
            <span><router-link :to="{path:'/admin'}">Dashboard</router-link></span>
        </div>
        <div>
            <div class="copyright text-right">
                  &copy; 
                  Desenvolvido por <a href="https://www.agenciaportes.com.br" target="_blank">Agência Portes</a>.
              </div>
        </div>
    </div>
  </footer>
</template>
<script>
  export default {}

</script>
<style>
#footer {
    background-color: #EEE;
    position:absolute;
    bottom:0;
    width:100%;
    height:60px;   /* Height of the footer */
    border-top: solid 1px #CCC;
}

</style>
