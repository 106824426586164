<template>
    <div id="top-menu">
        <nav class="w-100 navbar navbar-expand-lg">            
            <div class="navbar-collapse"  id="collapsibleNavId">
                <div class="row mr-auto">
                    <span class="col-6 col-md-12 align-self-center" id="bemvindo">Bem-vindo {{this.$store.getters.LoggedUser}}!</span>
                    <button class="col-6 navbar-toggler d-lg-none text-right" @click="showMobileMenu = !showMobileMenu" type="button" data-toggle="collapse" data-target="#collapsibleNavId" aria-controls="collapsibleNavId"
                        aria-expanded="false" aria-label="Toggle navigation">
                        <span class="navbar-toggler-icon">
                            <i class="fas fa-bars" style="color:#fff; font-size:28px;"></i>
                        </span>
                    </button>
                    <transition name="fade" class="col-12 navbar-collapse">
                        <MobileMenu v-show="showMobileMenu" @closeMobileMenu="showMobileMenu = false"></MobileMenu>
                    </transition>
                </div>
                <div id="navbar-options" class="float-right">
                    <i class="mr-1 fas fa-cogs" v-if="permissoes['Configurações'] && parseInt(permissoes['Configurações']['Cadastro de usuários']) > 0"></i>
                    <drop-down title="Configurações" class="mr-5" v-if="permissoes['Configurações'] && parseInt(permissoes['Configurações']['Cadastro de usuários']) > 0">
                        <div class="row" style="width: 40vw;">
                            <div class="col-6 pr-0">
                                <router-link class="dropdown-item" to="/admin/configuracoes/parametros"><i class="fas fa-align-center mr-2"></i>Parâmetros de Sistema</router-link>
                                <router-link class="dropdown-item" to="/admin/configuracoes/deficiencias"><i class="fab fa-accessible-icon mr-2"></i>Deficiências</router-link>
                                <!--<router-link class="dropdown-item bg-dark" to="/admin/config/tipotitulo">Exames</router-link>-->
                            </div>
                            <div class="col-6 pl-0">
                                <router-link class="dropdown-item" to="/admin/configuracoes/usuarios"><i class="fas fa-users mr-2"></i>Usuários</router-link>
                                <router-link class="dropdown-item" to="/admin/configuracoes/perfilAcesso"><i class="fas fa-users mr-2"></i>Perfil de Acesso</router-link>
                            </div>
                        </div>
                    </drop-down>
                    <a class="py-2 d-none d-md-inline-block text-center" @click="logout()" href="#"><i class="mr-1 fas fa-sign-out-alt"></i>Sair</a>
                </div>
            </div>
        </nav>
        <div class="site-header font-weight-bold sticky-top " style="z-index:50;">
            <img src="@/assets/images/logo23.png" alt="Logo gestor vida" class="float-left" style="height:50px;">
            <div class="container d-flex justify-content-center">
                <!-- <router-link to="/admin/agenda" class="col text-center">
                    <span class="py-2 d-none d-md-inline-block "><i class="fa fa-calendar-alt" aria-hidden="true"></i><br> Agenda</span>
                </router-link> -->
                <router-link to="/admin/empresas" class="col text-center" v-if="permissoes['Empresas'] && parseInt(permissoes['Empresas']['Empresas']) > 0">
                    <span class="py-2 d-none d-md-inline-block"><i class="fa fa-briefcase " aria-hidden="true"></i><br>Empresas</span>
                </router-link>
                <router-link to="/admin/funcionarios" class="col text-center" v-if="parseInt(permissoes['Pessoa Física']['Pessoa física']) > 0">
                    <span class="py-2 d-none d-md-inline-block" ><i class="fa fa-users " aria-hidden="true"></i><br>Pessoa física</span>
                </router-link>
                
                <!--<span class="py-2 d-none d-md-inline-block bg-dark"><i class="fas fa-bullhorn"></i><br>Treinamentos</span>-->
                
                <drop-down class="col text-center dropdown-header-menu">
                    <template slot="title">
                        <a href="#" class="dropdown-header-title">
                            <span class="py-2 d-none d-md-inline-block text-center">
                                <i class="fas fa-bullhorn"></i><br>
                                Treinamentos
                            </span>
                        </a>
                    </template>
                    <template slot="content">
                        <div class="dropdown-content animated fadeIn faster p-0 text-left" style="left:0;">
                            <router-link to="/admin/treinamentos" class="dropdown-item" v-if="permissoes.Treinamento['Cadastro de treinamentos'] && parseInt(permissoes.Treinamento['Cadastro de treinamentos']) > 0"><i class="fas fa-bullhorn mr-2"></i>Treinamentos</router-link>
                            <router-link class="dropdown-item" to="/admin/treinamentos/modelos" v-if="permissoes.Treinamento['Tipos de treinamento'] && parseInt(permissoes.Treinamento['Tipos de treinamento']) > 0"><i class="fas fa-bullhorn mr-2"></i>Tipos de Treinamento</router-link>
                            <router-link class="dropdown-item" to="/admin/treinamentos/normas" v-if="permissoes.Treinamento['Normas'] && parseInt(permissoes.Treinamento['Normas']) > 0"><i class="fas fa-bullhorn mr-2"></i>Normas</router-link>
                            <router-link class="dropdown-item" to="/admin/treinamentos/locais" v-if="permissoes.Treinamento['Locais de treinamento'] && parseInt(permissoes.Treinamento['Locais de treinamento']) > 0"><i class="fas fa-map-marked-alt mr-2"></i>Locais de Treinamento</router-link>
                            <router-link class="dropdown-item" to="/admin/treinamentos/instrutores" v-if="permissoes.Treinamento['Instrutores'] && parseInt(permissoes.Treinamento['Instrutores']) > 0"><i class="fas fa-users mr-2"></i>Instrutores</router-link>
                            <router-link class="dropdown-item" to="/admin/treinamentos/tipos" v-if="permissoes.Treinamento['Tipos de evento'] && parseInt(permissoes.Treinamento['Tipos de evento']) > 0"><i class="fas fa-calendar-minus mr-2"></i>Tipo de evento</router-link>
                            <router-link class="dropdown-item" to="/admin/configuracoes/cargos" v-if="permissoes.Treinamento['Cargos'] && parseInt(permissoes.Treinamento['Cargos']) > 0"><i class="fas fa-briefcase mr-2"></i>Cargos</router-link>
                        </div>
                    </template>
                </drop-down>

                <router-link to="/admin/relatorios" class="col text-center" v-if="permissoes.Treinamento['Cadastro de treinamentos'] && parseInt(permissoes.Treinamento['Cadastro de treinamentos']) > 1">
                    <span class="py-2 d-none d-md-inline-block" ><i class="fas fa-chart-bar"></i><br>Relatórios</span>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import MobileMenu from './MobileMenu'
import loginRepository from './../services/api/loginRepository'
export default {
    name: 'TopNavBar',
    components: {
        MobileMenu
    },
    data () {
        return {
            showMobileMenu: false,
            permissoes: []
        }
    },
    created() {
        this.permissoes = this.$store.getters.permissoes
        console.log(this.permissoes)
    },
    methods: {
        async logout () {
            this.$store.commit('SET_LOGOUT')
            await loginRepository.logout()
            this.$router.replace({name: 'AdminLogin'})
        }
    }
}
</script>

<style>
    #top-menu {
        background: linear-gradient(180deg, rgba(6,98,120,1) 0%, rgba(10,154,188,1) 100%);
    }
    nav {
        font-size: 0.8rem;
        margin-bottom: 0px;
        margin-top: 0px;
        font-weight: bold;
        background-color: var(--menu-color);
        color: #fff;
    }
    .site-header {
        color: white;
        background: var(--menu-color);
        
    }
    .site-header a {
        color: white;
    }
    .site-header a:hover, .dropdown-header-menu:hover {
        background-color: rgb(185,185,185, 0.30);
    }
    .dropdown-header-menu .dropdown-header-title:hover {
        text-decoration: none;
        background-color: transparent;
    }
    .site-header i {
        font-size: 22px;
    }
    .site-header a {
        font-size: 16px;
    }
    .card-sub-menu {
        font-size: 14px;
        color: var(--seccond-color);
        border: solid 1px var(--seccond-color);
    }
    .card-sub-menu a {
        color: var(--seccond-color);
    }
    .card-sub-menu i {
        font-size: 18px;
    }
    .site-header .active {
        background-color: rgb(185,185,185, 0.30);
    }
    #navbar-options > a {
        color: #fff;
    }

    .navbar-toggler {
        display: none;
    }
    @media only screen and (max-width: 992px) {
        .site-header {
            display: none;
        }
        #navbar-options {
            display: none;
        }
        #navbar-options a {
            width: 100%;
        }
    }

    @media (max-width: 765px) {
        .navbar-toggler {
            display: block;
        }
    }
</style>