<template>
  <div id="main-content" class="wrapper d-flex">
      <Load :isLoading="isLoading" />
      <div id="main-panel">
        <TopNavBar></TopNavBar>
        <Content></Content>
      </div>
      <ContentFooter></ContentFooter>
  </div>
</template>

<script>
import Content from './Content'
import TopNavBar from './TopNavBar'
import ContentFooter from './ContentFooter'
export default {
    components: {
        TopNavBar,
        ContentFooter,
        Content
    },
    computed: {
        isLoading() {
            return this.$store.getters.splashScreen
        }
    }
}
</script>

<style>
    #main-content {
        position: relative;
        min-height: 100vh;
    }
    #main-panel {
        width: 100%;
        padding-bottom: 60px;
    }
</style>